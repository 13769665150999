import React, { Suspense, lazy } from 'react';
import {Navigate, Route, BrowserRouter as Router, Routes} from "react-router-dom";

import 'primereact/resources/themes/lara-dark-purple/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import './App.css'
import PersonalSerialRedirection from "./Pages/Profile/PersonalSerialRedirection";
import CorporateSerialRedirection from "./Pages/Profile/CorporateSerialRedirection";

import RedirectExternal from "./Util/RedirectExternal";

const Profile = lazy(() => import('./Pages/Profile/Profile'));
const Entry = lazy(() => import('./Pages/Entry/Entry'));
const Home = lazy(() => import('./Pages/Home/Home'));
const EmailVerification = lazy(() => import('./Pages/EmailVerification/EmailVerification'));
const ArViewer = lazy(() => import('./Components/MindARThreeViewer/ArViewer'));
const ResetPassword = lazy(() => import('./Pages/ResetPassword/ResetPassword'));

const App: React.FC = () => {
	const blockAPI = (pathname: string) => {
		if (pathname.startsWith('/api/v1')) {
			return <Navigate to="/" />;
		}
		return null;
	};

	return (
		<Router>
			<Suspense fallback={<div>Loading...</div>}>
				<Routes>
					<Route path="*" element={blockAPI(window.location.pathname)} />
					{/*<Route path="/" element={<Navigate to="/login" />} />*/}
					{/* Uncomment and change url accordingly in production build */}
					<Route path="/" element={<RedirectExternal to="https://xcard.webxite.my" />} />
					<Route path="/login" element={<Entry />} />
					<Route path="/scan" element={<ArViewer />} />
					<Route path="/home" element={<Home />} />
					<Route path="/verify_email/:verification_token" element={<EmailVerification />} />
					<Route path="/reset_password/:verification_token" element={<ResetPassword />} />

					{/* Serial URL */}
					<Route path="/:company_uid/:company_user_id" element={<CorporateSerialRedirection />} />
					<Route path="/q/:b2c_user_id" element={<PersonalSerialRedirection />} />

					{/* Username / profileLink URL */}
					<Route path="/p/:b2c_username_or_profile_link" element={<Profile userType="personal"/>} />
					<Route path="/:company_uid/p/:b2b_username_or_profile_link" element={<Profile userType="corporate"/>} />
				</Routes>
			</Suspense>
		</Router>
	);
};

export default App;